import { FetchError } from '../errors/fetchError';

const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));

const RETRIES = 2;

export const fetchWithRetries = async <T>(
  url: string,
  attempt = 0,
): Promise<T> => {
  try {
    const response = await fetch(url);

    if (response.ok) {
      return response.json();
    }

    throw new FetchError(url, response.status);
  } catch (e) {
    if (attempt === RETRIES) {
      throw e;
    }

    await wait(2 ** attempt * 10);

    return fetchWithRetries(url, attempt + 1);
  }
};
