import {
  BlocksConfig,
  WidgetControllerConfig,
  WidgetMetaData,
} from '@wix/blocks-widget-services-types';
import { ILogger } from '../common/ILogger';
import { FetchDescriptorError } from '../errors/fetchDescriptorError';
import { generateWidgetDescriptorUrl } from './urlBuilder';
import { fetchWithRetries } from './fetchWithRetries';
import { isFetchError } from '../errors/fetchError';
import { experiments } from '../common';

const EMPTY_META_DATA: WidgetMetaData = {};

const FETCH_WIDGET_DESCRIPTOR_INTERACTION = 'fetch-widget-descriptor';
const DESCRIPTOR_WARMUP_KEY_PREFIX = '$blocks-warmup-descriptor';

async function fetchDescriptor(descriptorUrl: string, logger?: ILogger) {
  try {
    return await fetchWithRetries<WidgetMetaData>(descriptorUrl);
  } catch (e) {
    // This means that the descriptor does not exists for the widget
    if (isFetchError(e) && e.statusCode === 403) {
      logger?.reportInfo('missing widget descriptor', { descriptorUrl });
      return EMPTY_META_DATA;
    }

    throw e;
  }
}

export async function fetchRemoteMetaData(
  widgetConfig: WidgetControllerConfig,
  blocksConfig: BlocksConfig,
  logger?: ILogger,
): Promise<WidgetMetaData> {
  if (!widgetConfig.config.devCenterWidgetId) {
    logger?.reportInfo('missing devCenterWidgetId', {
      type: widgetConfig.config.type,
    });

    return EMPTY_META_DATA;
  }

  const warmupDescriptorKey = `${DESCRIPTOR_WARMUP_KEY_PREFIX}_${widgetConfig.config.devCenterWidgetId}`;

  if (
    experiments.isOpen('specs.blocks-client.addWidgetDescriptorsToWarmupData')
  ) {
    const warmedUpDescriptor =
      widgetConfig.wixCodeApi.window.warmupData.get(warmupDescriptorKey);

    if (warmedUpDescriptor) {
      return warmedUpDescriptor;
    }
  }

  const descriptorUrl = generateWidgetDescriptorUrl(
    widgetConfig.config.devCenterWidgetId,
    blocksConfig,
  );

  try {
    logger?.interactionStarted(FETCH_WIDGET_DESCRIPTOR_INTERACTION);
    const descriptor = await fetchDescriptor(descriptorUrl, logger);

    if (
      experiments.isOpen('specs.blocks-client.addWidgetDescriptorsToWarmupData')
    ) {
      widgetConfig.wixCodeApi.window.warmupData.set(
        warmupDescriptorKey,
        descriptor,
      );
    }

    logger?.interactionEnded(FETCH_WIDGET_DESCRIPTOR_INTERACTION);

    return descriptor;
  } catch (e) {
    throw new FetchDescriptorError(descriptorUrl, e);
  }
}
